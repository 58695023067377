<template>
  <main-layout>
    <v-container grid-list-xs>
      <v-row>
        <v-col
          cols="12"
          lg="10"
        >
          <h2 class="headline mb-3">Wachtdiensten</h2>
          <base-divider
            color="accent"
            dense
          />
          <p>
            U kan een beroep doen op de dokter van wacht voor dringende medische problemen tijdens
            <strong>weekdagen</strong>
            van <nobr><strong>19u tot 8u</strong></nobr> de volgende ochtend.
            <br> Tijdens het <strong>weekend</strong> is dit van vrijdagavond <strong>19u tot </strong>
            en met maandagmorgen <strong>8u</strong>.
          </p>
          <p>
            De dokter van wacht kan u bereiken via het centrale <br>oproepnummer: <strong>070/22 22 36</strong>
            van de huisartsenwachtdienst Tervuren.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </main-layout>
</template>

<script>
  import MainLayout from '@/layouts/Main.vue'
  import BaseDivider from '@/components/Divider.vue'

  export default {
    components: {
      MainLayout,
      BaseDivider
    },
    head () {
      return {title: this.$t('wachtdiensten')}
    }
  }
</script>
